@import url('https://fonts.googleapis.com/css?family=Nunito:400,700')

@import 'bulma/sass/utilities/_all.sass'
@import 'bulma/sass/base/_all.sass'
@import 'bulma/sass/form/_all.sass'
@import 'bulma/sass/elements/_all.sass'
@import 'bulma/sass/components/_all.sass'
@import 'bulma/sass/layout/_all.sass'
@import 'bulma/sass/grid/_all.sass'

body
  background: #fff9e8
  height: 100vh
  width: 100vw
  position: fixed
  touch-action: none
  overflow: hidden

main
  display: flex
  flex-direction: column
  max-height: calc(100vh - 105px)
  &.standalone
    max-height: 100vh

.navbar
  display: flex
  flex: 1 0 $navbar-height
  box-shadow: 0em 0em .15em .15em rgba(10, 10, 10, .05)
  justify-content: center
  font-size: 2em
  svg
    margin: 0 .25em
  .user-online
    color: $green
  .connection-problem
    color: $orange
  li.is-active .open-gallery
    color: #238cd1

.messages
  padding: 0 1em
  overflow-y: scroll
  display: flex
  flex-direction: column
  flex-basis: 1 1 100%

.keyboard
  display: flex
  flex-direction: column
  position: relative

.message
  display: flex
  cursor: pointer
  flex-basis: 0
  padding: .5em 1em 0.5em
  min-width: 10em
  min-height: min-content
  white-space: pre-wrap
  overflow-wrap: break-word
  flex-direction: column
  .date
    font-weight: normal
    font-size: .75em
    color: #666
    font-style: italic
    bottom: .675em
  .message-text
    padding: 0 0 .5em
  .message-text, .message-media
    place-self: flex-start
    display: flex
    flex-direction: row
  &.from-us
    background: #53d769
    text-align: right
    place-self: flex-end
    .message-text, .message-media
      place-self: flex-end
    .date
      right: 1.5em
      span
        color: #999
      .is-primary
        color: #0066ff
  &.from-them
    place-self: flex-start
    .date
      left: 1.5em
  .message-sending
    border: none
    background-color: transparent
    bottom: 0.25em
    right: 1em
    font-size: .7em
  .message-image
    background: #000
    max-width: 6em
    max-height: 6em
    box-shadow: 0em 0em .35em .35em rgba(10, 10, 10, .15)
    // display: inline-block
    align-self: flex-end
    margin: 0 0.5em 1.25em 0.5em

.keyboard
  .keyboard-controls
    display: flex
    flex: 1 1
    align-self: flex-end
    position: absolute
    right: 3px
    bottom: 3px
  .control
    flex: 1 1 100%
    .input
      width: 100%
  .field
    .control:first-child
      width: 100%
  .pending-fragments
    bottom: 3.75em
    left: 0
    right: 0
    height: 4em
    .fragment-preview
      display: inline-block
      box-shadow: 0em 0em .15em .15em rgba(10, 10, 10, .15)
      border-radius: 10px
      overflow: hidden
      margin: 0 0 0 1em
      img
        max-width: 4em
        max-height: 4em
      .delete-fragment
        top: -4px
        right: -4px
        background: #f11
        color: #fff
        border-radius: 24px
        font-size: .8em
  .textarea
    flex: 1 1 100%
    padding-right: 6em
    min-height: 48px
    max-height: 48px
    &:focus
      min-height: 82px
      max-height: auto

.pinpad
  display: flex
  flex-direction: column
  justify-content: space-evenly
  height: 90vh
  overflow: hidden
  .pin-preview
    text-align: center
    flex: 1
    flex-direction: column
    justify-content: space-evenly
    display: flex
  .buttons
    display: flex
    flex-direction: column
    flex: 4
    div
      display: flex
      flex-direction: row
      flex: 1
    .button
      flex-basis: 0
      flex-grow: 1
      font-size: 1.75em
      padding: 1.5em
      margin: 0.5em 1em
      width: 2em
      height: 2em
      border-radius: 6em
      box-shadow: .15em .15em 10px 1px rgba(10, 10, 10, .05)

.viewer
  .viewer-prev, .viewer-next
    font-size: 2em
    position: absolute
    z-index: 10
    cursor: pointer
    color: rgba(255, 255, 255, 0.3)
    box-shadow: .15em .15em 10px 1px rgba(10, 10, 10, .05)
  .viewer-prev
    left: .5em
  .viewer-next
    right: .5em

.gallery
  background: #333
  width: 100vw
  height: calc(100vh - 105px)
  &.standalone
    height: 100vh
    position: relative
  .gallery-content
    padding: 3em 0 105px
    overflow: scroll
    height: 100vh
    .gallery-label
      color: #fff
  ul
    margin: 0 auto
    width: 98vw
    display: block
    text-align: center
  li
    display: inline-block
    width: 23vw
    max-height: 23vw
    padding: 4vw 2vw
    white-space: nowrap
    margin-right: 2em
    img, video
      box-shadow: .15em .15em 10px 1px rgba(10, 10, 10, .25)
      max-width: 6em
      max-height: 6em
    .video-icon
      color: rgba(255, 255, 255, 0.75)
      filter: drop-shadow(0px 0px 3px #000)
      font-size: 2em
      position: relative
      left: -.5em
  .gallery-filter
    position: absolute
    top: 0
    background: #333
    li
      padding: 1em
      width: auto

.video-call
  display: fixed
  background: #000
  top: 0
  left: 0
  right: 0
  bottom: 0
  height: 100vh
  text-align: center
  padding: 10vh
  div
    padding: 2.5vh
  button
    font-size: 3em
    border-radius: 2em
    height: 4em
    width: 4em
.blurred
  filter: blur(15px)
  opacity: .5

.settings-wrapper
  ul
  .tabs .show-sensitive.is-active a
    background-color: $red
    border-color: $red

.modal-content
  .image img
    width: auto
    height: auto

.message-selected
  width: 100%
  box-shadow: 0em 0em .4em .4em rgba(10, 10, 10, .25)

.message-options
  padding-top: .5em
  display: flex
  flex-direction: row
  justify-content: flex-end
  button
    font-size: .8em
    margin: 0 0 0 .6em

.incoming-call
  position: fixed
  bottom: calc(105px + 3em)
  right: 0
  padding: 1em
  div
    display: flex
    flex-direction: row
    .button
      box-shadow: 0em 0em .15em .15em rgba(10, 10, 10, .05)
      margin: .25em
      font-size: 2em
      width: 2em
      height: 2em
      border-radius: 2em
      color: #fff
      &.answer
        background: $green
      &.hangup
        background: $red

@keyframes wobble
  0%
    transform: translateX(0%)
  15%
    transform: translateX(-25%) rotate(-5deg)
  30%
    transform: translateX(20%) rotate(3deg)
  45%
    transform: translateX(-15%) rotate(-3deg)
  60%
    transform: translateX(10%) rotate(2deg)
  75%
    transform: translateX(-5%) rotate(-1deg)
  100%
    transform: translateX(0%)

.incoming-message .message-text
  animation-name: wobble
  animation-duration: 5s
  animation-fill-mode: both
